p {
  padding: 0;
  margin: 0;
}

body {
  overflow-x: hidden;
}

.App {
  overflow-y: auto;
  background-color: #090909;
  min-height: 100vh;
  font-size: calc(10px + 2vmin);
  color: white;
  
  // @media screen and (orientation:landscape) { //Full screen video
    
    .BGVideo {
      height: 100vh;
      object-fit: cover;
      position: absolute;
    }

    .Opening {
      height: 100vh;
    }

  // }

  // @media screen and (orientation:portrait) { //Partical screen video
    
  //   .Opening {
  //     height: calc(100vw * 0.56); //Dynamically set based on 
  //   }

  // }

  // Opening section

  .Opening {
    width: 100vw;
    display: grid;
    align-items: center;

    .titleText {  
      font-size: calc(100vw / 10);
      font-weight: bolder;
      opacity: 1;

      text-align: center;

      .topText {
        color: #fff;
      }
  
      .bottomText {
        color: #cdcccc;
      }

    }


    .BGVideo {
      width: 100%;
      opacity: 20%;
      position: absolute;
      background-color: black;
    }
    
    //About me section

  }

  @media screen and (orientation:landscape) { 
    
    .lowercontent {
      width: 70vw;
      margin-left: 15vw;
      margin-right: 15vw;
    }

  }

  @media screen and (orientation:portrait) { 
    
    .lowercontent {
      width: 90vw;
      margin-left: 5vw;
      margin-right: 5vw;
    }

  }

  .Seperator {
    background-color: #6d6d6d52;
    border-radius: 2em;

    width: 90%;
    margin-left: 5vw;

    height: 4px;
    margin-top: 2em;
    margin-top: 2em;
  }

  .Showreel {
    text-align: center;
  

    .ShowreelVideo {
      width: 80%;
      border-radius: 0.6em;
    }
  }

  .AboutMe {
    text-align: center;
  }
}

